import React from "react";
import WhatsAppLogo from "../images/wsp.png";

const Footer = () => {
  return (
    <footer id="contact" style={{marginTop: '3%'}}>
      <div className="container footer-text">
        <div className="row copyright">
          <div className="col-md-12 text-center">
            <p>
              <small className="block">
                Created by{" "}
                <a
                  href="https://wa.me/543815496469"
                  target="_blank"
                  rel="noreferrer"
                >
                  Invitapp
                  <img
                    src={WhatsAppLogo}
                    alt="whatsappLogo"
                    className="contact-image"
                  />
                </a>
                <div>&copy; 2023. All Rights Reserved.</div>
              </small>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
