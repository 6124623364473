import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";

function AnimatedDiv({ children }) {
  const [isAnimating, setIsAnimating] = useState(false);
  const ref = useRef(null);
  const prevScrollY = useRef(0);

  const animation = useSpring({
    from: { transform: "translateY(100%)" },
    to: {
      transform: isAnimating ? "translateY(0%)" : "translateY(100%)",
    },
    config: { tension: 300, friction: 70 },
  });

  useEffect(() => {
    const handleScroll = (e) => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY.current) {
        // el usuario se está desplazando hacia abajo
        const top = ref.current.getBoundingClientRect().top;
        const bottom = ref.current.getBoundingClientRect().bottom;

        if (top < window.innerHeight && bottom > 0) {
          setIsAnimating(true);
        }
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <animated.div style={animation} ref={ref}>
      {children}
    </animated.div>
  );
}

export default AnimatedDiv;
