import React from "react";
import PlaylistLogo from "../images/spotify.png";
import MusicLogo from "../images/music.png";
import AnimatedDiv from "./AnimatedDiv";

const Playlist = () => {
  return (
    <div id="playlist">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <AnimatedDiv>
              <h2>¡Ayudanos con la música!</h2>
              <p>
                Compartinos ESA canción que no puede faltar en nuestra fiesta!
              </p>
            </AnimatedDiv>
            <div>
              <img src={MusicLogo} alt="musicLogo" className="music-logo" />
            </div>
            <div className="col-md-4 col-sm-4 align playlist-buttons">
              <a
                target="_blank"
                href="https://forms.gle/G31juWHLWsyxp94D9"
                rel="noreferrer"
              >
                <button className="btn btn-default btn-block location-button">
                  RECOMENDAR
                </button>
              </a>
              <a
                target="_blank"
                href="https://open.spotify.com/playlist/1RSvnnsUV81glTKPgfWB8E?si=053125ba29804176&pt=939a8a00148bbc1ea4da4c896558b537"
                rel="noreferrer"
              >
                <button className="btn btn-default btn-block location-button">
                  PLAYLIST
                  <img
                    src={PlaylistLogo}
                    alt="playlistLogo"
                    className="spotify-logo"
                  />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playlist;
