import React from "react";
import Countdown from "react-countdown";
import CustomCountdownRenderer from "./CustomCountdownRenderer";

const CountdownHeader = () => {
  const currentDate = new Date();
  const targetDate = new Date("2024-01-13");
  const timeUntilTarget = targetDate.getTime() - currentDate.getTime();

  return (
    <div>
      <header
        id="fh5co-header"
        className="fh5co-cover"
        role="banner"
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container"></div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <div className="display-t title-top first-container">
              <div className="home-title">
                <p className="home-subtitle">¡NOS CASAMOS!</p>
                <h1 className="couple-name">Federico & Valentina</h1>
                <Countdown
                  date={currentDate.getTime() + timeUntilTarget}
                  renderer={CustomCountdownRenderer}
                  zeroPadTime={2}
                />
                <div className="home-countdown-location"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default CountdownHeader;
