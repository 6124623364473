import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Testimonials1 from "../images/bridesmaid-1.jpg";
import Testimonials2 from "../images/bridesmaid-2.jpg";
import Testimonials3 from "../images/bridesmaid-3.jpg";

const Testimonials = () => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="row">
          <div className="row animate-box">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <AnimatedDiv>
                <span>Deseos de</span>
                <h2>Nuestros amigos</h2>
              </AnimatedDiv>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ justifyContent: "center" }}>
              <div
                className="wrap-testimony"
                style={{ width: "70%", margin: "0 auto" }}
              >
                <div
                  id="myCarousel"
                  class="carousel slide"
                  data-ride="carousel"
                  data-interval="10000"
                >
                  <ol class="carousel-indicators">
                    <li
                      data-target="#myCarousel"
                      data-slide-to="0"
                      class="active"
                    ></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2"></li>
                  </ol>

                  <div class="carousel-inner" style={{ marginTop: "-20px" }}>
                    <div class="item active" style={{ height: "450px" }}>
                      <div className="testimony-slide active text-center">
                        <img
                          src={Testimonials1}
                          alt="testimonials1"
                          style={{
                            borderRadius: "50%",
                            width: "15%",
                          }}
                        />
                        <blockquote>
                          <p>
                            Estoy convencida que son el uno para el otro. Les
                            deseo mucha felicidad en este nuevo camino!
                          </p>
                        </blockquote>
                      </div>
                    </div>

                    <div class="item" style={{ height: "450px" }}>
                      <div className="testimony-slide active text-center">
                        <img
                          src={Testimonials2}
                          alt="testimonials2"
                          style={{
                            borderRadius: "50%",
                            width: "15%",
                          }}
                        />
                        <blockquote>
                          <p>
                            Uno cosecha lo que siembra, y ustedes sembraron
                            mucho amor. Estoy ansioso por ser testigo del
                            próximo capítulo de sus vidas gracias por seguir
                            compartiendolas con nosotros!
                          </p>
                        </blockquote>
                      </div>
                    </div>

                    <div class="item" style={{ height: "450px" }}>
                      <div className="testimony-slide active text-center">
                        <img
                          src={Testimonials3}
                          alt="testimonials3"
                          style={{
                            borderRadius: "50%",
                            width: "15%",
                          }}
                        />
                        <blockquote>
                          <p>
                            Espero de corazón que todo lo que venga en adelante
                            sea pura felicidad. Aquí estamos sus amigos para
                            apoyarlos en cada paso que den. Felicidades y a
                            fesjetar!! Los quiero
                          </p>
                        </blockquote>
                      </div>
                    </div>
                  </div>

                  <a
                    class="left carousel-control left-arrow"
                    href="#myCarousel"
                    data-slide="prev"
                  >
                    <span class="glyphicon glyphicon-chevron-left"></span>
                  </a>
                  <a
                    class="right carousel-control right-arrow"
                    href="#myCarousel"
                    data-slide="next"
                  >
                    <span class="glyphicon glyphicon-chevron-right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
