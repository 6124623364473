import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Travel from "../images/Present.jpg"

const Present = () => {
  return (
    <div id="present">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <AnimatedDiv>
              <h2>Nuestro regalo</h2>
              <p>
                es que nos acompañes en este día tan especial. Pero si quieres
                tener un detalle con nosotros podes colaborar con nuestra luna
                de miel
              </p>
            </AnimatedDiv>
          </div>
          <div className="col-md-6 bank-data">
            <div className="feature-left">
                <span className="icon">
                  <i className="icon-credit-card present-icons"></i>
                </span>
                <div className="feature-copy">
                  <h3>CBU</h3>
                  <p>0170070140000078454112.</p>
                </div>
            </div>

            <div className="feature-left" data-animate-effect="fadeInLeft">
              <span className="icon">
                <i className="icon-paper present-icons"></i>
              </span>
              <div className="feature-copy">
                <h3>ALIAS</h3>
                <p>FEDE.VALE.BODA</p>
              </div>
            </div>

            <div className="feature-left" data-animate-effect="fadeInLeft">
              <span className="icon">
                <i className="icon-user present-icons"></i>
              </span>
              <div className="feature-copy">
                <h3>TITULAR</h3>
                <p>Federico García</p>
              </div>
            </div>

            <div className="feature-left" data-animate-effect="fadeInLeft">
              <span className="icon">
                <i className="icon-box present-icons"></i>
              </span>
              <div className="feature-copy">
                <h3>BUZON</h3>
                <p>disponible en el salón</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 travel-photo-container">
            <div
              className="fh5co-video fh5co-bg"
              style={{
                backgroundImage: `url(${Travel})`,
                backgroundSize: "contain",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Present;
