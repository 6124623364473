import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gallery1 from "../images/Gallery1.jpg";
import Gallery2 from "../images/Gallery2.jpg";
import Gallery3 from "../images/Gallery3.jpg";
import Gallery4 from "../images/Gallery4.jpg";
import Gallery5 from "../images/Gallery5.jpg";
import Gallery6 from "../images/Gallery6.jpg";
import AnimatedDiv from "./AnimatedDiv";

const Gallery = () => {
  const PrevArrow = ({ onClick }) => (
    <button className="carousel-arrow prev" onClick={onClick}>
      <i className="fa fa-angle-left carousel-icon-left" aria-hidden="true"></i>
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button className="carousel-arrow next" onClick={onClick}>
      <i
        className="fa fa-angle-right carousel-icon-right"
        aria-hidden="true"
      ></i>
    </button>
  );

  const settings = {
    autoplay: false,
    speed: 1500,
    dots: false,
    infinite: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div id="gallery" className="bg-gallery">
      <div className="row">
        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
        <AnimatedDiv>
          <span style={{color: 'white'}}>Nuestros momentos juntos</span>
          <h2 style={{color: 'white'}}>Galería</h2>
        </AnimatedDiv>
        </div>
      </div>
      <Slider {...settings} className="gallery-carousel">
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery1} alt="" />
          <a href={Gallery1} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery2} alt="" />
          <a href={Gallery2} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery3} alt="" />
          <a href={Gallery3} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery4} alt="" />
          <a href={Gallery4} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery5} alt="" />
          <a href={Gallery5} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery6} alt="" />
          <a href={Gallery6} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default Gallery;
