import Navbar from "./components/Navbar";
import CountdownHeader from "./components/CountdownHeader";
import Invitation from "./components/Invitation";
import Story from "./components/Story";
import Events from "./components/Events";
import Present from "./components/Present";
import Assistance from "./components/Assistance";
import Gallery from "./components/Gallery";
import Testimonials from "./components/Testimonials";
import Thanks from "./components/Thanks";
import Playlist from "./components/Playlist";
import Footer from "./components/Footer";
import GotoTopButton from "./components/GoToTopButton";
import Dresscode from "./components/Dresscode";

function App() {
  return (
    <div id="page">
      <Navbar />
      <CountdownHeader />
      <Invitation />
      <Story />
      <Events />
      <Dresscode />
      <Present />
      <Assistance />
      <Testimonials />
      <Gallery />
      <Thanks />
      <Playlist />
      <Footer />
      <GotoTopButton />
    </div>
  );
}

export default App;
