import React from "react";
import AnimatedDiv from "./AnimatedDiv";

const Events = () => {
  return (
    <div id="events" className="fh5co-bg">
      <div className="overlay"></div>
      <div className="container">
        <div className="row" style={{ marginTop: "-5%" }}>
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <AnimatedDiv>
              <h2>Eventos</h2>
              <span style={{ fontSize: "large" }}>
                LUNES 15 DE ENERO DE 2024
              </span>
            </AnimatedDiv>
          </div>
        </div>
        <div className="row">
          <div className="display-t">
            <div className="home-title">
              <div className="col-md-10 col-md-offset-1">
                <div className="col-md-6 col-sm-6 text-center">
                  <div className="event-wrap animate-box">
                    <h3>Ceremonia</h3>
                    <div className="event-col">
                      <i className="icon-clock"></i>
                      <span>20:30 HS</span>
                    </div>
                    <div className="event-col">
                      <i className="icon-calendar"></i>
                      <span>Parroquia San Martin de Porres</span>
                    </div>
                    <a
                      target="_blank"
                      href="https://goo.gl/maps/MHqAc5v1vjRHL4T88?coh=178573&entry=tt"
                      rel="noreferrer"
                    >
                      <button className="btn btn-default btn-block location-button">
                        Como llegar
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 text-center">
                  <div className="event-wrap animate-box">
                    <h3>FIESTA</h3>
                    <div className="event-col">
                      <i className="icon-clock"></i>
                      <span>21:30</span>
                    </div>
                    <div className="event-col">
                      <i className="icon-calendar"></i>
                      <span>Salón de fiestas KEKÉ</span>
                    </div>
                    <a
                      target="_blank"
                      href="https://goo.gl/maps/3u8R7y7e3ZZMyGMC7?coh=178573&entry=tt"
                      rel="noreferrer"
                    >
                      <button className="btn btn-default btn-block location-button">
                        Como llegar
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
