import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import ConfirmAssistanceBg from "../images/ConfirmAssistance.jpg";

const Assistance = () => {
  return (
    <div
      id="confirm"
      className="fh5co-bg"
      style={{
        backgroundImage: `url(${ConfirmAssistanceBg})`,
        marginTop: "5%",
        marginBottom: "5%",
      }}
    >
      <div className="overlay"></div>
      <AnimatedDiv>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2>Confirmá tu asistencia</h2>
              <p style={{ color: "white" }}>
                Te pedimos que completes este formulario para confirmar tu
                presencia hasta el día 15 de diciembre de 2023. Gracias!
              </p>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "5%" }}>
            <div className="col-md-10 col-md-offset-1">
              <div class="col-md-4 col-sm-4 align">
                <a
                  target="_blank"
                  href="https://forms.gle/j8PU72RfZzEu6pHY6"
                  rel="noreferrer"
                >
                  <button className="btn btn-default btn-block location-button">
                    CONFIRMAR
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </AnimatedDiv>
    </div>
  );
};

export default Assistance;
