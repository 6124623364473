import React from "react";
import MusicPlayer from "./MusicPlayer";

const Navbar = () => {
  return (
    <div>
      <nav className="fh5co-nav" role="navigation">
        <div className="container">
          <div className="row">
            <div className="col-xs-2" style={{ display: "flex" }}>
              <div id="player-container">
                <MusicPlayer />
              </div>
            </div>
            <div className="col-xs-10 text-right menu-1">
              <ul>
                <li className="active">
                  <a href="Home.html">INICIO</a>
                </li>
                <li>
                  <a href="#invitation">INVITACIÓN</a>
                </li>
                <li>
                  <a href="#story">HISTORIA</a>
                </li>
                <li className="has-dropdown">
                  <a href="#events">EVENTOS</a>
                  <ul className="dropdown">
                    <li>
                      <a href="#events">Ceremonia</a>
                    </li>
                    <li>
                      <a href="#events">Fiesta</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#present">REGALO</a>
                </li>
                <li>
                  <a href="#thanks">AGRADECIMIENTO</a>
                </li>
                <li>
                  <a href="#confirm">CONFIRMAR</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
