import React from "react";
import MenDresscode from "../images/MenDresscode.jpg";
import WomenDresscode from "../images/WomenDresscode.jpg";
import AnimatedDiv from "./AnimatedDiv";

const Dresscode = () => {
  return (
    <div id="dresscode">
      <div className="container">
          <div className="">
            <div
              className="col-md-8 col-md-offset-2 text-center fh5co-heading"
              style={{ marginBottom: "20px" }}
            >
              <AnimatedDiv>
              <h2>Dresscode</h2>
              </AnimatedDiv>
            </div>
          </div>
        <div className="couple-wrap">
          <div className="col-md-6 p-0">
            <div>
              <img
                src={MenDresscode}
                alt="man-dress"
                className="dresscode-responsive"
              />
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div>
              <img
                src={WomenDresscode}
                alt="women-dress"
                className="dresscode-responsive"
              />
            </div>
          </div>
          <div
            className="col-md-8 col-md-offset-2 text-center dresscode-style"
            style={{
              marginTop: "40px",
              fontSize: "20px",
            }}
          >
            <p>La vestimenta para el gran día será ELEGANTE</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dresscode;
