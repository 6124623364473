import React from "react";
import AnimatedDiv from "../components/AnimatedDiv"
import Story1 from "../images/Story1.jpg";
import Story2 from "../images/Story2.jpg";
import Story3 from "../images/Story3.jpg";
import Story4 from "../images/Story4.jpg";

const CoupleHistory = () => {
  return (
    <div id="story">
      <div className="container ">
        <AnimatedDiv>
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box ">
            <span>Un poco de</span>
            <h2>Nuestra Historia</h2>
          </div>
        </div>
        </AnimatedDiv>
        <div className="row">
          <div className="col-md-12 col-md-offset-0">
            <ul className="timeline animate-box">
              <li className="animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: `url(${Story1})` }}
                ></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h3 className="timeline-title">Nos conocimos</h3>
                    <span className="date">14 de enero de 2018</span>
                  </div>
                  <div className="timeline-body">
                    <p>
                      {" "}
                      Ambos trabajábamos para la misma empresa pero nunca antes
                      nos habíamos cruzado. Nos vimos por primera vez y
                      comenzamos a hablar luego de una cena de fin de año
                      organizada por la empresa.
                    </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: `url(${Story2})` }}
                ></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h3 className="timeline-title">Nuestra primera cita</h3>
                    <span className="date">20 de enero de 2018</span>
                  </div>
                  <div className="timeline-body">
                    <p>
                      {" "}
                      Luego de largos intercambios de mensajes y, por
                      insistencia de nuestros amigos, salimos por primera vez.
                      Fuimos a merendar a una pastelería de nuestra ciudad y la
                      chispa estaba cada vez más encendida. La merienda se
                      estiró y terminamos cenando juntos también.
                    </p>
                  </div>
                </div>
              </li>
              <li className="animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: `url(${Story3})` }}
                ></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h3 className="timeline-title">Somos novios</h3>
                    <span className="date">26 de mayo de 2018</span>
                  </div>
                  <div className="timeline-body">
                    <p>
                      {" "}
                      Luego de unos meses volvimos a la vieja cafetería donde
                      tuvimos nuestra primera cita. Fue entonces cuando Federico
                      propuso que fuéramos novios. Desde entonces sabíamos que
                      ibamos a pasar el resto de nuestras vidas juntos.
                    </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: `url(${Story4})` }}
                ></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h3 className="timeline-title">Nos comprometimos</h3>
                    <span className="date">3 de febrero de 2023</span>
                  </div>
                  <div className="timeline-body">
                    <p>
                      {" "}
                      Tuvimos un momento íntimo rodeado de nuestros amigos en la
                      playa. Fue entonces cuando la GRAN pregunta llegó. ¿Ya se
                      imaginan quién la hizo?
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoupleHistory;
