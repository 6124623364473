import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import CoupleVideo from "../images/ExampleVideo.mp4";

const Thanks = () => {
  return (
    <div id="thanks" className="fh5co-section-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <AnimatedDiv>
              <h2>Gracias!</h2>
              <p>
                por ser parte de este día tan importante para nosotros. También
                por ser parte de nuestra historia y de todo el camino que
                recorrimos hasta aquí. A nuestra familia, amigos y a todos los
                que estuvieron, están y sin dudas sabemos que seguirán estando,
                muchas gracias! Estamos felices de compartirlo con todos
                ustedes!
              </p>
            </AnimatedDiv>
          </div>
        </div>

        <div className="row">
          <div>
            <div className="video-container">
              <video
                className="couple-video"
                src={CoupleVideo}
                controls
                type="video/mp4"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
