import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Groom from "../images/groom.jpg";
import Bride from "../images/bride.jpg";

const Invitation = () => {
  return (
    <div id="invitation">
      <div className="container">
        <AnimatedDiv>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2>Hola!</h2>
              <p>Te invitamos a celebrar con nosotros nuestra boda el día</p>
              <h3>13 de Enero de 2024</h3>
            </div>
          </div>
        </AnimatedDiv>
        <div className="couple-wrap">
          <div className="couple-half">
            <AnimatedDiv>
              <div className="groom diego">
                <img src={Groom} alt="groom" />
              </div>
              <div className="desc-groom">
                <h3>Federico García</h3>
                <p>
                  {" "}
                  Es Ingeniero Agrónomo. Tucumano. Es un apasionado del fútbol,
                  deporte que le inculcó su papá desde chico y pasión que
                  comparten. Es el "asador del grupo", el que nunca falta a una
                  reunión.
                </p>
              </div>
            </AnimatedDiv>
          </div>
          <p className="heart text-center">
            <i className="icon-heart2"></i>
          </p>
          <div className="couple-half">
            <AnimatedDiv>
              <div className="bride">
                <img src={Bride} alt="groom" className="img-responsive" />
              </div>
              <div className="desc-bride">
                <h3>Valentina Posse</h3>
                <p>
                  Es profesora de matemática. Cordobesa. Amante de la naturaleza
                  y de los animales. Ama a sus amigas y haría todo por ellas. Es
                  una apasasionada de la música, por lo que en su tiempo libre
                  toma clases de canto.
                </p>
              </div>
            </AnimatedDiv>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
